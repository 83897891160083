import styled from "styled-components/macro"
import { fontFamily, palette, fontWeight, pxToRem } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import { breakpoints } from "../../../utils/style-utils"

const bkgFn = ({ percentage = 0 }) =>
  `linear-gradient(to right, ${palette.lightBlue3} 0%, ${palette.lightBlue3} ${percentage}%, ${palette.lightBlue3} ${percentage}%, ${palette.gray90} ${percentage}%, ${palette.gray90} 100%)`

export const BracketPoolSetupWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0 3rem;
  margin: 0 auto;

  & > .title {
    font-family: ${fontFamily.base};
    font-weight: ${fontWeight.black};
    font-size: 2rem;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.25px;
    text-align: center;
    margin-top: 0.5rem;
    color: ${palette.gray20};
    /* display: block; */
  }
  & > .content-wrap {
    background: ${palette.white};
    margin-top: 1.5rem;
    width: 41.25rem;
    box-sizing: border-box;
    border: 1px solid ${palette.gray90};
    border-radius: 0.5rem;
    padding: 1rem 1.5rem 0;
  }

  @media (max-width: ${pxToRem(breakpoints.handheld)}) {
    & > .content-wrap {
      width: 100vw;
      border-radius: 0;
      padding: 0 1rem;
    }

    & > .title {
      margin-top: 1rem;
      line-height: 1.2;
      letter-spacing: -0.96px;
    }
  }
`

export const SetupStepsWrap = styled.div<{ percentage?: number }>`
  display: flex;
  flex-direction: row;
  width: calc(100% - 10rem);
  margin: 0 auto;
  justify-content: space-between;
  position: relative;
  margin-bottom: 3.5rem;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    top: 1.25rem;
    background: ${bkgFn};
  }
`

export const StepWrap = styled.div<{ title?: string }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  box-sizing: border-box;
  color: ${palette.gray75};
  background-color: ${palette.white};
  font-family: ${fontFamily.base};
  font-size: 12px;
  font-style: normal;
  font-weight: ${fontWeight.bold};
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
  border: 0.5rem solid ${palette.white};

  &::after {
    content: "${({ title }) => title || ""}";
    position: absolute;
    top: 2.5rem;
    font-family: ${fontFamily.base};
    display: flex;
    font-size: 14px;
    font-style: normal;
    font-weight: ${fontWeight.regular};
    line-height: 20px;
    letter-spacing: -0.1px;
    text-align: center;
    color: ${palette.gray50};
  }

  &.is-active {
    color: ${palette.lightBlue3};

    &::after {
      font-weight: ${fontWeight.bold};
      color: ${palette.gray20};
    }
  }
`
export const CreatePoolDisclaimer = styled.div`
  margin-top: 1rem;
  font-size: 0.625rem;
  line-height: 0.875rem;
  color: ${palette.gray40};
  width: 41.25rem;
  & a {
    text-decoration: underline;
    & :hover {
      color: ${palette.lightBlue3};
    }
  }

  @media (max-width: ${pxToRem(breakpoints.handheld)}) {
    width: 100vw;
    padding: 0 0.5rem;
  }
`
