import { Form, Formik, FormikProps } from "formik"
import * as React from "react"
import { getSegment, getFullPoolSettings } from "../../../../common/pool-settings-setup"
import { Logo } from "../../../components/Logos"
import { nextStepFor, stepMappingFor, weightsAndRoundsField } from "../components/StepMappings"
import StepMobileHelper from "../components/StepMobileHelper"
import { IPoolSetupStepProps } from "../PoolSetupTypes.d"
import { InviteStepCustomContainer, StyledForm } from "../styles/CommonPoolSetup.styles"

const FrontendOnlyFields: string[] = [
  // "format",
]

const emptyArray = [] as never[]

const isDiff = (v1, v2) => JSON.stringify(v1) !== JSON.stringify(v2)

class PoolSetupStepForm extends React.PureComponent<IPoolSetupStepProps> {
  public getFullPoolSettings() {
    return getFullPoolSettings(this.props.pool)
  }

  public getDefaultPoolSettings() {
    return getFullPoolSettings(this.props.pool, true)
  }

  public getInitialValues = () => {
    const poolSettings = this.getFullPoolSettings()
    const currentPoolSettings = Object.assign({}, poolSettings)
    const rb = currentPoolSettings.roundBonuses || []
    if (!rb.length) {
      currentPoolSettings.roundBonuses = this.getDefaultPoolSettings().roundBonuses
    }
    return currentPoolSettings
  }

  public onSubmit = async (values: any) => {
    const { currentStep, pool, switchStep, mutation, isUpdate } = this.props
    const stepMappings = stepMappingFor(pool.gameInstanceUid)
    const stepMapping = stepMappings.find((m) => m.path === currentStep)
    const field: string = (stepMapping && stepMapping.field) || ""
    if (field) {
      let value
      const currentPoolSettings = this.getFullPoolSettings()
      const updatedPoolSettings = {} as any
      if (field === weightsAndRoundsField) {
        if (isDiff(currentPoolSettings.gameWeightType, values.gameWeightType)) {
          updatedPoolSettings.gameWeightType = values.gameWeightType
        }
        if (isDiff(currentPoolSettings.roundBonusType, values.roundBonusType)) {
          updatedPoolSettings.roundBonusType = values.roundBonusType
        }
      } else if (field === "format") {
        value = values[field]
        const tournamentIds = currentPoolSettings.tournamentIds || emptyArray
        const segmentPoolSettings = getSegment(pool).poolSettings
        if (segmentPoolSettings.__typename !== "PoolSettings") {
          throw new Error(`Invalid pool settings ${segmentPoolSettings.__typename}`)
        }
        const baseIds = segmentPoolSettings.tournamentIds || emptyArray
        if (values.format === "SINGLE_TOURNAMENT" && tournamentIds.length !== 1) {
          updatedPoolSettings.tournamentIds = [baseIds[0]]
        }
      } else {
        value = values[field]
        if (!FrontendOnlyFields.includes(field) && isDiff(currentPoolSettings[field], value)) {
          updatedPoolSettings[field] = value
        }
      }
      const roundBonuses = currentPoolSettings.roundBonuses || []
      if (updatedPoolSettings.roundBonusType === "NONE" && roundBonuses.length) {
        updatedPoolSettings.roundBonuses = []
      }
      if (updatedPoolSettings.roundBonusType === "STANDARD" && roundBonuses.length < 1) {
        updatedPoolSettings.roundBonuses = values.roundBonuses
      }
      // console.dir(values);
      const nextStep = nextStepFor(currentStep, pool)
      const isUpdateOverride = isUpdate && currentStep === "game-weights" && "nothing"
      switchStep(isUpdateOverride || nextStep, pool.id)
      if (Object.keys(updatedPoolSettings).length) {
        const variables = {
          poolId: pool.id,
          seasonId: pool.season.id,
          poolSettings: updatedPoolSettings,
        }
        await mutation({ variables })
      }
    } else {
      console.warn(`unknown submit step: ${currentStep}`)
    }
  }

  public LogoEl = () => {
    const { pool } = this.props
    if (!pool) {
      return null
    }
    return <Logo style={{ margin: "2vh 0", maxHeight: "11.25rem" }} gameInstanceUid={pool.gameInstanceUid} season={pool.season} />
  }

  public render() {
    const { pool } = this.props
    const values = this.getInitialValues()
    const stepMappings = stepMappingFor(pool.gameInstanceUid)
    const stepMapping = stepMappings.find((m) => m.path === this.props.currentStep)
    // console.dir(stepMapping)
    if (!stepMapping) {
      return null
    }
    const { Component, ...rest } = stepMapping
    const isReactivatedPool = !!pool && !!pool.parentPoolId
    if (stepMapping.path === "invite") {
      if (isReactivatedPool) {
        return (
          <InviteStepCustomContainer as={"div" as any}>
            {Component && <Component {...this.props} {...rest} />}
            <StepMobileHelper pool={pool} values={values} sections={stepMapping.choices || emptyArray} />
          </InviteStepCustomContainer>
        )
      }
      return (
        <StyledForm as={"div" as any}>
          {Component && <Component {...this.props} {...rest} />}
          <StepMobileHelper pool={pool} values={values} sections={stepMapping.choices || emptyArray} />
        </StyledForm>
      )
    }
    return (
      <Formik
        initialValues={values}
        onSubmit={this.onSubmit}
        enableReinitialize={true}
        component={(formikBag: FormikProps<any>) => {
          return (
            <StyledForm as={Form}>
              {Component && <Component {...this.props} {...rest} {...formikBag} />}
              <StepMobileHelper pool={pool} values={formikBag.values} sections={stepMapping.choices || emptyArray} />
            </StyledForm>
          )
        }}
      />
    )
  }
}
export default PoolSetupStepForm
