import SpinnerSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Spinner"
import React, { useEffect, useState } from "react"

const wrapStyles: React.CSSProperties = {
  fontSize: 0,
  lineHeight: 0,
}
const SVGFromUrl = ({ url }: { url: string }) => {
  const [svgData, setSvgData] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    fetch(url)
      .then((res) => res.text())
      .then(setSvgData)
      .finally(() => setIsLoading(false))
  }, [url])
  if (isLoading) {
    return <SpinnerSvg />
  }
  return <span dangerouslySetInnerHTML={{ __html: svgData || "" }} style={wrapStyles} />
}

export default SVGFromUrl
