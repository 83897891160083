import styled from "styled-components/macro"
import palette from "../../../../common/palette"
import { colorFor, getThemeValues } from "../../../../common/theme-helpers"
import { layout, pxToRem } from "../../../utils/style-utils"
const poolSetupStepFormRowMarginBot = 48
const exposeHelpBpH = 600

const bg = ({ theme }) => (getThemeValues(theme).isDarkSplashBg ? palette.gray5 : palette.white)
const boxShadowColor = ({ theme }) => (getThemeValues(theme).isDarkSplashBg ? palette.black : `rgba(0,0,0,0.2)`)
const headerColor = colorFor

export const StepMobileHelperContainer = styled.div`
  background: ${bg};
  box-shadow: inset 0px ${pxToRem(20)} ${pxToRem(20)} ${pxToRem(-10)} ${boxShadowColor};
  transition: all 0.8s ease-out;
  max-height: 0;
  position: relative;
  overflow: hidden;
  flex: 0 0 auto;
  [class*="-enter-active"] & {
    opacity: 0;
  }
  [class*="-enter"]:not([class*="-enter-done"]) & {
    opacity: 0;
  }
  &.open {
    max-height: ${pxToRem(400)};
  }
  @media (min-height: ${pxToRem(exposeHelpBpH)}) {
    & {
      max-height: ${pxToRem(400)};
    }
  }
  @media (min-width: ${pxToRem(layout.formHiddenLabelsW)}) {
    display: none;
  }
`
export const StepMobileHelperSection = styled.div`
  margin-bottom: ${pxToRem(16)};
  font-size: ${pxToRem(12)};
  line-height: 1.2em;
  padding: 0 ${pxToRem(16)};
  & h6 {
    color: ${headerColor};
    font-size: ${pxToRem(18)};
    margin: ${pxToRem(20)} 0 ${pxToRem(8)};
  }
`
export const StepMobileHelperBtn = styled.button`
  font-size: ${pxToRem(10)};
  width: ${pxToRem(poolSetupStepFormRowMarginBot)};
  height: ${pxToRem(poolSetupStepFormRowMarginBot)};
  min-height: ${pxToRem(poolSetupStepFormRowMarginBot)};
  margin: 0 auto;
  transition: opacity 0.2s ease-in-out, transform 0.3s linear;
  will-change: opacity;
  &:disabled {
    visibility: hidden;
  }
  @media (min-width: ${pxToRem(layout.formHiddenLabelsW)}) {
    display: none;
    pointer-events: none;
  }
  &.open {
    transform: rotate(180deg);
  }
  @media (hover: hover) {
    :hover:not(:active) {
      opacity: 0.8;
    }
  }
  :active {
    opacity: 0.6;
  }

  @media (min-height: ${pxToRem(exposeHelpBpH)}) {
    & {
      display: none !important;
    }
  }
`
