import styled from "styled-components/macro"
import { Form as FormikForm } from "formik"
import { Title as CenteredTitle } from "../CreateBracketPoolForm"
import bracketTheme from "@cbs-sports/sports-shared-client/build/cjs/utils/BracketTheme"

export const Title = styled(CenteredTitle)`
  text-align: left;
  text-transform: uppercase;
`
export const Form = styled(FormikForm)`
  display: flex;
  flex-direction: column;

  .poolForm__actionBoxWrapper {
    display: grid;
    grid-template-columns: repeat(11, 1fr);
    position: relative;
    margin-top: 0.5rem;

    &:before {
      content: "";
      position: absolute;
      height: 1px;
      width: calc(100% + 3rem);
      left: -1.5rem;
      display: block;
      background-color: ${bracketTheme.colors.overLight.white90};
      top: 0;
    }

    .poolForm__actionBox {
      grid-column: 4/-4;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 1rem 0;

      * {
        margin-left: 1rem;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
`
export const FormWrapper = styled.div`
  background: ${bracketTheme.colors.overLight.white};
  margin-top: var(--grid-gap);
  box-sizing: border-box;
  border: 1px solid ${bracketTheme.colors.overLight.white90};
  border-radius: 0.5rem;
  padding: 1.25rem 1.5rem 0;
`
