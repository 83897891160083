import React from "react"
import LocalKey from "../../../Base/LocalKey"
import { IStepMobileHelperProps } from "../PoolSetupTypes.d"
import { StepMobileHelperBtn, StepMobileHelperContainer, StepMobileHelperSection } from "../styles/StepMobileHelper.styles"

const nbsp = "\u00A0"

class StepMobileHelper extends React.PureComponent<IStepMobileHelperProps> {
  public scrolling = false

  public scrollToBot = () => {
    // document.querySelector('.step-mobile-helper-container').scrollIntoView({behavior: "smooth", block: "end", inline: "end"})
    const scrollingElement = document.getElementById("layout-wrapper") // (document.scrollingElement || document.body);
    if (scrollingElement) {
      scrollingElement.scrollTop = scrollingElement.scrollHeight
      if (this.scrolling) {
        window.requestAnimationFrame(this.scrollToBot)
      }
    }
  }

  public onClick = (value, update) => (evt) => {
    evt.preventDefault()
    update(value ? null : "open")
    if (!value) {
      this.scrolling = true
      window.setTimeout(() => {
        this.scrolling = false
      }, 600)
      this.scrollToBot()
    }
  }

  public render() {
    const { sections, pool, values } = this.props
    return (
      <LocalKey keyName="StepMobileHelper">
        {(localProps) => {
          // console.log(`StepMobileHelper`)
          // console.dir(this.props)
          return (
            <React.Fragment>
              <StepMobileHelperBtn
                disabled={sections.length === 0}
                as="button"
                type="button"
                className={`${!!localProps.value ? "open" : ""} icon-moon-chevron-down`}
                onClick={this.onClick(localProps.value, localProps.update)}
              />
              <StepMobileHelperContainer className={`${!!localProps.value ? "open" : ""} step-mobile-helper-container`}>
                {sections.map(({ value, humanFunc, bodyFunc, description, human }) => (
                  <StepMobileHelperSection key={value}>
                    <h6>{(humanFunc && humanFunc(pool, values)) || human || nbsp}</h6>
                    <p>{(bodyFunc && bodyFunc(pool, values)) || description || nbsp}</p>
                  </StepMobileHelperSection>
                ))}
              </StepMobileHelperContainer>
            </React.Fragment>
          )
        }}
      </LocalKey>
    )
  }
}

export default StepMobileHelper
