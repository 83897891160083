import React from "react"
import { buildClassNames as cx } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import { IStepMapping } from "../PoolSetupTypes"
import { BracketPoolSetupWrap, StepWrap, SetupStepsWrap, CreatePoolDisclaimer } from "../styles/BracketPoolSetupCommon.styles"
import constants, { NCAAB_MANAGER_IMPRESSION_URL_SRC, NCAAW_MANAGER_IMPRESSION_URL_SRC } from "../../../../common/constants"
import SVGFromUrl from "../../../components/SVGFromUrl"
import LogoWithAd from "../../../components/LogoWithAd"
import { useDeviceType } from "../../../Base/DeviceType"

const staticRoot = `${constants.DOMAIN}/static`

const getPercent = (currentStep: string | null, steps: IStepMapping[]) => {
  const index = steps.findIndex((x) => x.path === currentStep)
  const length = steps.length
  if (index === 0) {
    return 2
  }
  if (index === length) {
    return 100
  }

  const total = length - 1
  return Math.round((index / total) * 100)
}

interface BracketPoolSetupCommonProps {
  currentStep: string
  children: React.ReactElement | React.ReactNode
  steps: IStepMapping[]
  disclaimer?: string
  isNcaaw: boolean
}

const BracketPoolSetupCommon = ({ currentStep, children, steps, disclaimer, isNcaaw }: BracketPoolSetupCommonProps) => {
  const deviceType = useDeviceType()
  const isMobile = deviceType === "handheld"

  const percentage = getPercent(currentStep, steps)
  const currentStepIndex = Math.max(
    steps.findIndex((x) => x.path === currentStep),
    0,
  )

  let impressionUrlSrc = NCAAB_MANAGER_IMPRESSION_URL_SRC
  if (isNcaaw) {
    impressionUrlSrc = NCAAW_MANAGER_IMPRESSION_URL_SRC
  }

  const mobileLogoWith = isMobile ? "50px" : undefined

  return (
    <BracketPoolSetupWrap>
      <LogoWithAd impressionUrl={impressionUrlSrc} sponsorLogoWidth={mobileLogoWith} logoWidth={mobileLogoWith} />
      <span className="title" data-cy="create-pool-title">
        Create Pool
      </span>
      <div className="content-wrap">
        {!isMobile && (
          <SetupStepsWrap percentage={percentage}>
            {steps.map((step, index) => {
              const { title } = step
              const iconSrc = `${staticRoot}/svg-files/${index + 1}.svg`
              return (
                <StepWrap
                  key={index}
                  className={cx({
                    "is-active": index <= currentStepIndex,
                  })}
                  title={title}
                >
                  <SVGFromUrl url={iconSrc} />
                </StepWrap>
              )
            })}
          </SetupStepsWrap>
        )}
        <div>{children}</div>
      </div>
      {disclaimer && <CreatePoolDisclaimer dangerouslySetInnerHTML={{ __html: disclaimer }} data-cy="create-pool-disclaimer" />}
    </BracketPoolSetupWrap>
  )
}

export default BracketPoolSetupCommon
