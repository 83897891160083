import styled from "styled-components/macro"
import { colorFor } from "../../../../common/theme-helpers"
import { ButtonBase } from "../../../components/Button"
import { FillScreen, FlexCols, FlexRow } from "../../../components/FlexComponents"
import { fontWeight, layout, palette, pxToRem } from "../../../utils/style-utils"
import { inputBorderFor, inputBgColorFor, inputFocusStyleFor, placeholderColorFor } from "../../../../common/form-theme-helpers"

export const PoolFormContainer = styled(FillScreen)`
  flex: 1 0 auto;
  justify-content: flex-start;
  padding: ${({ theme }) => pxToRem(theme.isNexusStyles ? 16 : 0)} 0 0;
  ${({ theme }) => (!theme.isNexusStyles ? "" : "min-height: unset !important; flex: 1;justify-content: flex-start;")}
  color: ${colorFor};

  &.white-bkg {
    background-color: ${palette.white};
    background-image: url(/static/sport-assets/basketball/pool-setup-bg.svg);
    background-repeat: repeat-x;
  }

  &.is-bracket {
    overflow: initial;
    padding-top: 0;
  }

  &.fill-hack__true {
    flex: none;
  }

  @media (max-width: ${pxToRem(layout.formHiddenLabelsW)}) {
    ${({ theme }) => (!theme.isNexusStyles ? "min-height: 100%" : "flex: 1 1 100%")};
    padding: ${({ theme }) => pxToRem(!theme.isNexusStyles ? 0 : 16)} 0 0;
  }
`
export const PoolFormTransitionContainer = styled(FlexCols)`
  &.uses-animation--true {
    perspective: ${pxToRem(1200)};
  }
  flex: 1 1 100%;
  position: relative;
  & > *,
  & > * > * {
    width: 100%;
    flex: 1 0 100%;
    display: flex;
    flex-flow: column;
  }
`

const spinnerSize = 42
export const FormButton = styled(ButtonBase)`
  position: relative;
  width: ${pxToRem(300)};
  @media (max-width: ${pxToRem(layout.formHiddenLabelsW)}) {
    max-width: none;
    width: 100%;
  }
  &.is-submitting--true {
    color: rgba(255, 255, 255, 0);
    & svg {
      opacity: 1;
      transform: scale(1);
    }
  }
  & > .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: ${pxToRem(-spinnerSize / 2)} 0 0 ${pxToRem(-spinnerSize / 2)};
    width: ${pxToRem(spinnerSize)};
    height: ${pxToRem(spinnerSize)};
    transition: all 0.2s ease-in-out;
    opacity: 0;
    transform: scale(0);
    color: ${palette.white};
  }
`

export const StepCustomButton = styled(ButtonBase)`
  max-width: ${pxToRem(280)};
  flex-wrap: wrap;
  & span {
    color: #88d7ff;
    line-height: ${pxToRem(18)};
  }
  @media (max-width: ${pxToRem(layout.formHiddenLabelsW)}) {
    width: 100%;
    max-width: none;
  }
`

export const FormActionsContainer = styled(FlexRow)`
  padding: ${pxToRem(20)} 0;
  @media (max-width: ${pxToRem(layout.formHiddenLabelsW)}) {
    padding: ${pxToRem(60)} 0;
  }
`
export const InviteStepCustomContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex: 1 0 100%;
  flex-flow: column;
  max-width: ${pxToRem(layout.wrapperWidth)};
  padding-bottom: 4vh;

  & input {
    border: ${pxToRem(1)} solid ${inputBorderFor};
    background-color: ${inputBgColorFor};
    &:focus {
      ${inputFocusStyleFor};
    }
    &::placeholder {
      color: ${placeholderColorFor};
    }
  }
  & fieldset:not(:empty) {
    margin-bottom: ${pxToRem(8)};
    background-color: #ffffff61;
    border-radius: ${pxToRem(6)};
  }
  @media (min-width: ${pxToRem(layout.formHiddenLabelsW)}) {
    & form > *:last-child {
      margin-left: ${pxToRem(12)};
    }
    & form {
      padding-top: 4vh;
    }
  }
  @media (max-width: ${pxToRem(layout.formHiddenLabelsW)}) {
    & form > *:first-child {
      padding-left: ${pxToRem(6)};
      padding-right: ${pxToRem(6)};
    }
  }
`

export const StyledForm = styled.form`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex: 1;
  flex-flow: column;
  max-width: ${pxToRem(layout.wrapperWidth)};

  & input:not([type="checkbox"]) {
    max-width: ${pxToRem(450)};
    padding: ${pxToRem(10)} ${pxToRem(16)};
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(31)};
    letter-spacing: ${pxToRem(1.4)};
    border-radius: ${pxToRem(3)};
    display: block;
    outline: none;
    margin: 0 auto ${pxToRem(32)};
    width: 100%;
    font-weight: ${fontWeight.semiBold};
    border: ${pxToRem(1)} solid ${inputBorderFor};
    background-color: ${inputBgColorFor};
    transition: all 0.2s ease;
    color: inherit;
    &:focus {
      ${inputFocusStyleFor};
    }
    &::placeholder {
      color: ${placeholderColorFor};
    }
  }
  & input:not([type="checkbox"]),
  label {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: ${pxToRem(450)};
    width: 100%;
    padding: ${pxToRem(10)} ${pxToRem(16)};
    font-size: ${pxToRem(15)};
    line-height: ${pxToRem(31)};
    border-radius: ${pxToRem(3)};
    font-weight: ${fontWeight.semiBold};
  }
  & input[type="checkbox"] {
    margin-right: 0.5em;
  }
  & .form-row {
    margin-top: 0.5rem;
  }
  & .form-row input {
    margin-bottom: 0;
  }
  & .form-input--checkbox label {
    white-space: normal;
    font-size: 0.9rem;
    line-height: 1.3;
    padding-left: 0;
    padding-right: 0;
  }
  & input.has-errors {
    border-color: ${palette.formError};
  }
  &.is-modal--true {
    padding: 0;
    min-height: 60vh;
    position: relative;
  }

  &.custom-step--invite {
    padding-bottom: 4vh;
  }
  &.custom-step--invite fieldset:not(:empty) {
    margin-bottom: ${pxToRem(8)};
    background-color: #ffffff61;
    border-radius: ${pxToRem(6)};
  }
  @media (min-width: ${pxToRem(layout.formHiddenLabelsW)}) {
    &.custom-step--invite form > *:last-child {
      margin-left: ${pxToRem(12)};
    }
  }
  @media (max-width: ${pxToRem(layout.formHiddenLabelsW)}) {
    &.custom-step--invite form > *:first-child {
      padding-left: ${pxToRem(6)};
      padding-right: ${pxToRem(6)};
    }
  }

  &.is-update--true {
    display: block;
  }

  & .season-disclaimer {
    display: block;
    padding: 1rem;
    text-align: center;
  }
`

export const PoolSetupStepFormCols = styled(FlexCols)`
  padding: ${pxToRem(4)};
  max-width: ${pxToRem(320)};
  margin: 0 auto;
  min-height: ${pxToRem(65)};
  & p {
    padding: ${pxToRem(28)} 0;
    font-size: ${pxToRem(16)};
    font-weight: ${fontWeight.semiBold};
    text-align: center;
    line-height: ${pxToRem(24)};
    @media (max-width: ${pxToRem(layout.formHiddenLabelsW)}) {
      display: none;
    }
  }
  @media (min-width: ${pxToRem(layout.useMobileNavW)}) {
    &.is-desktop-alt {
      position: fixed;
      right: 0;
      bottom: 1rem;
      overflow: hidden;
      & div {
        display: none !important;
      }
      & button:hover {
        opacity: 0.8;
      }
      & button:active {
        opacity: 0.6;
      }
      & button:after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-color: transparent transparent transparent ${palette.buttonBlue1};
        border-style: solid;
        border-width: ${pxToRem(10)};
        position: absolute;
        right: ${pxToRem(28)};
        top: ${pxToRem(26)};
      }
      & * {
        background: none !important;
        border: none !important;
        font-size: ${pxToRem(22)};
        white-space: nowrap;
        margin-right: ${pxToRem(12)};
      }
    }
  }
`
