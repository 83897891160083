import React, { FC } from "react"
import GenericConfirmationModal, { IGenericConfirmationModalProps } from "./GenericConfirmationModal"

const CONFIRMATION_TITLE = "Discard changes?"
const CONFIRMATION_MESSAGE = "Your changes have not been saved."
const CTA_BUTTON_LABEL = "Discard"

type DiscardChangesConfirmationPropsType = Pick<IGenericConfirmationModalProps, "isOpen" | "onClose" | "onConfirm" | "loading">

const DiscardChangesConfirmation: FC<DiscardChangesConfirmationPropsType> = ({ isOpen, onClose, onConfirm, loading }) => {
  return (
    <GenericConfirmationModal
      isOpen={isOpen}
      title={CONFIRMATION_TITLE}
      message={CONFIRMATION_MESSAGE}
      ctaButtonLabel={CTA_BUTTON_LABEL}
      onClose={onClose}
      onConfirm={onConfirm}
      loading={loading}
    />
  )
}

export default DiscardChangesConfirmation
