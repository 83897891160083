import { PoolDetailsFragment } from "../../../../__generated__/PoolDetailsFragment"
import { IStepMapping } from "../PoolSetupTypes.d"
import PoolSetupStepInvite from "./PoolSetupStepInvite"
import PoolSetupRules from "./PoolSetupRules"

export const weightsAndRoundsField = "WEIGHTS_OR_ROUNDS"
const None = () => null

const bracketMappings = [
  {
    path: "",
    title: "Info",
    subtitle: "Setup Options",
    Component: None,
  },
  {
    path: "game-weights",
    title: "Rules",
    subtitle: `How would you like to score your bracket?`,
    Component: PoolSetupRules,
    field: "gameWeightType",
  },
  {
    path: "invite",
    title: "Invite",
    subtitle: "Here’s how to invite friends...",
    Component: PoolSetupStepInvite,
  },
] as IStepMapping[]

const stepToPath = ({ path }) => path
export const stepMappingFor = (gameInstanceUid?: string) => {
  if (gameInstanceUid === undefined) {
    return []
  }
  if (/(ncaab|ncaaw)-tournament/.test(gameInstanceUid)) {
    // return allStepMappings.filter(({ path }) => bracketSteps.includes(path))
    return bracketMappings
  }
  return []
}

export const nextStepFor = (currentStep: string, pool: PoolDetailsFragment) => {
  const stepMapping = stepMappingFor(pool.gameInstanceUid)
  const stepOrder = stepMapping.map(stepToPath)
  const currentIndex = stepOrder.indexOf(currentStep)
  if (stepOrder.length <= currentIndex + 1) {
    return null
  }
  return stepOrder[currentIndex + 1]
}
