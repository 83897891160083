import React, { FC, useEffect, useState, useMemo, useCallback } from "react"
import { Prompt } from "react-router-dom"
import { Location } from "history"
import { Formik, FormikErrors } from "formik"
import { ApolloError, MutationFunction } from "@apollo/client"
import Button from "@cbs-sports/sports-shared-client/build/cjs/components/Button"
import { PoolDetailsQuery_pool } from "../../../../../__generated__/PoolDetailsQuery"
import { UpsertPoolMutation, UpsertPoolMutationVariables } from "../../../../../__generated__/UpsertPoolMutation"
import { TPoolRouteProps } from "../../../../../routes.d"
import constants from "../../../../../common/constants"
import { IStandardPoolPageContainerProps, StandardPoolPageContainer } from "../../../PoolPages/components/PoolPage"
import { extractValidationError } from "../../../../components/Form"
import { getFullPoolSettings } from "../../../../../common/pool-settings-setup"
import { RoundModifierTypeEnum } from "../../../../../common/enums"
import { PoolFormGeneralFields, FullUpsertBracketPoolType, PoolFormRulesFields, FormHelperText, FormErrorText } from "../BracketPoolFormComponents"
import { FormWrapper, Title, Form } from "./BracketPoolSettings.styles"
import { withoutDomain } from "../../../../../common/url-utils"
import DiscardChangesConfirmation from "../../../PoolPages/components/EntryModals/DiscardChangesConfirmation"
import AnalyticScreen from "../../../../components/AnalyticsScreen"
import { scrollToTopAfterTimeout } from "../../../../components/ScrollToTopOnMount"
import Analytics from "../../../../utils/analytics"

interface IProps extends TPoolRouteProps {
  pool: PoolDetailsQuery_pool
  mutation: MutationFunction<UpsertPoolMutation, UpsertPoolMutationVariables>
  canEdit: boolean
  error?: ApolloError
}

const POOL_PAGE_HEADER_PROPS: IStandardPoolPageContainerProps["poolPageHeaderLayoutOptions"] = {
  showAddBracketControl: false,
  showNavigation: false,
}

const BracketPoolSettingsForm: FC<IProps> = ({ pool, mutation, canEdit, history, error, ...rest }) => {
  const [showNavigationAlert, setShowNavigationAlert] = useState<boolean>(false)
  const [navigateTo, setNavigateTo] = useState<Location | string | null>(null)
  const [navigationConfirmed, setNavigationConfirmed] = useState<boolean>(false)
  const returnUrl = useMemo(() => {
    const query = new URLSearchParams(rest.location.search)
    return query.get("returnUrl") ?? `${withoutDomain(pool.url)}/standings`
  }, [pool.url, rest.location])
  const closeNavigationAlert = () => setShowNavigationAlert(false)

  const confirmNavigation = () => {
    setShowNavigationAlert(false)
    setNavigationConfirmed(true)
    // clean up history state
    if (history.location.state?.["actions"]) {
      const {
        location: { state },
      } = history
      delete state["actions"]
      history.replace({ ...history.location, state })
    }
  }

  const handleNavigation = useCallback(
    (location: Location) => {
      if (navigationConfirmed) {
        return true
      }
      setShowNavigationAlert(true)
      setNavigateTo(location)
      return false
    },
    [navigationConfirmed],
  )

  useEffect(() => {
    if (!(navigationConfirmed && navigateTo)) {
      return
    }
    if (typeof navigateTo === "string") {
      history.push(navigateTo)
    } else {
      history.push(navigateTo.pathname)
    }
  }, [navigationConfirmed, navigateTo, history])

  useEffect(() => {
    return () => {
      scrollToTopAfterTimeout()
    }
  }, [])

  const initialValues = useMemo<FullUpsertBracketPoolType>(() => {
    const generalValues = {
      poolId: pool.id,
      name: pool.name,
      slogan: pool.slogan ?? "",
      usesMagicLink: pool.usesMagicLink,
      password: (!pool.usesMagicLink && pool.password) || "",
      seasonId: pool.season.id,
      avatarUrl: pool.avatarUrl,
      constitution: pool.constitution ?? "",
    }
    const rulesValues = getFullPoolSettings(pool)
    const rb = rulesValues.roundBonuses ?? []
    if (!rb.length) {
      const defaultFullPoolSettings = getFullPoolSettings(pool, true)
      rulesValues.roundBonuses = defaultFullPoolSettings.roundBonuses
    }
    const { roundBonuses, roundModifiers, maxEntriesPerUser, mainTiebreaker, gameWeightType, roundBonusType, includeMessageBoard } = rulesValues
    const computedRoundModifiers = roundModifiers ?? (roundBonuses ?? []).map(() => RoundModifierTypeEnum.NONE)
    const initialOpenInvites = (pool?.poolSettings.__typename === "PoolSettings" && pool.poolSettings.openInvites) || false
    return {
      ...generalValues,
      poolSettings: {
        roundBonuses,
        roundModifiers: computedRoundModifiers as RoundModifierTypeEnum[],
        maxEntriesPerUser,
        mainTiebreaker,
        gameWeightType,
        roundBonusType,
        includeMessageBoard,
        openInvites: initialOpenInvites,
      },
    }
  }, [pool])

  const goBack = useCallback(
    (hasSaved?: boolean) => {
      setNavigationConfirmed(true)
      const {
        location: { state },
      } = history
      const navigationState = hasSaved
        ? {
            actions: {
              displaySettingsSavedModal: true,
              ...(history.location.state?.actions?.showInviteAllOnSave && { showInviteAllOption: true }),
            },
          }
        : {}
      history.push({ pathname: returnUrl, state: { ...state, ...navigationState } })
    },
    [history, returnUrl],
  )

  const handleSubmit = useCallback(
    (variables) => {
      Analytics.trackAction("pool settings", "update pool", variables.poolSettings?.openInvites ? "open" : "closed")
      return mutation({ variables })
        .then(() => {
          goBack(true)
        })
        .catch((err) => {
          const apiErrors = extractValidationError(err)
          console.log(apiErrors.errors)
        })
    },
    [mutation, goBack],
  )

  const handleValidation = useCallback((values: FullUpsertBracketPoolType) => {
    const errors: FormikErrors<FullUpsertBracketPoolType> = {}

    if (!values.name?.trim()) {
      errors.name = "Please fill in your pool name"
    } else if ((values.name?.trim()?.length ?? 0) < constants.POOL_NAME_MIN_LENGTH) {
      errors.name = `Pool name must be at least ${constants.POOL_NAME_MIN_LENGTH} characters`
    }

    if (!values.usesMagicLink) {
      if (!values.password) {
        errors.password = "Please fill in your pool password"
      } else if ((values.password?.trim()?.length || 0) < constants.POOL_PASSWORD_MIN_LENGTH) {
        errors.password = `Password must be at least ${constants.POOL_PASSWORD_MIN_LENGTH} characters`
      }
    }

    return errors
  }, [])

  const errorNodes = useMemo(() => {
    if (!error) {
      return null
    }
    const { errors } = extractValidationError(error)
    return errors && Object.keys(errors).map((k) => <FormErrorText>{errors[k].join()}</FormErrorText>)
  }, [error])

  const requiredFieldsNote = (
    <>
      {errorNodes}
      <FormHelperText dataCyChildren="req-fields-lbl-below-errors">*Required Field(s)</FormHelperText>
    </>
  )

  return (
    <StandardPoolPageContainer {...rest} history={history} poolPageHeaderLayoutOptions={POOL_PAGE_HEADER_PROPS}>
      <AnalyticScreen feature="brackets" subfeature={`edit-settings`} title={`Edit Settings`} deviceType={rest.poolData?.deviceType} />
      <FormWrapper>
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validate={handleValidation}>
          {(formik) => {
            const hasChanges = JSON.stringify(initialValues) !== JSON.stringify(formik.values)
            const rulesFields = <PoolFormRulesFields formik={formik} pool={pool} additionalFields={requiredFieldsNote} />
            return (
              <>
                <Prompt message={handleNavigation} when={hasChanges} />
                <Title>Edit Pool Settings</Title>
                <Form>
                  <PoolFormGeneralFields formik={formik} gameInstanceUid={rest.poolData.gameInstanceUid} isUpdate additionalFields={rulesFields} />
                  <div className="poolForm__actionBoxWrapper">
                    <div className="poolForm__actionBox">
                      <Button variant="secondary" onClick={() => goBack()} data-cy="cancel-btn">
                        Cancel
                      </Button>
                      <Button
                        variant="primary"
                        type="submit"
                        withLoading
                        loading={formik.isSubmitting}
                        disabled={!formik.isValid || !hasChanges || formik.isSubmitting}
                        data-cy="save-btn"
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </Form>
                <DiscardChangesConfirmation isOpen={showNavigationAlert} onClose={closeNavigationAlert} onConfirm={confirmNavigation} />
              </>
            )
          }}
        </Formik>
      </FormWrapper>
    </StandardPoolPageContainer>
  )
}

export default BracketPoolSettingsForm
